import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CSpSliderMedia,
  CHeroMedia02,
  CFocalCard,
  CLinkList,
  CBtnList,
  Search,
  LPickup,
  LPlan,
  LBgSect,
  CHeroCard,
} from '../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            en: 'guest room',
            ja: '宿泊',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/stay/kv.png',
              },
              imgSp: {
                src: '/assets/images/stay/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [],
            current: {
              label: '宿泊',
            },
          }}
        />
      </CJumbotron>
      <section className="u_mbExlarge">
        <LWrap exClass="u_mbExLarge">
          <h2 className="c_headingLv2 u_mtExLarge">
            非日常に誘う
            <br />
            ヨーロッパの趣と設え
          </h2>
          <p className="c_sectLead">
            古き良きヨーロッパの荘園領主の館のような気品溢れる多彩なゲストルーム。
            <br />
            仙台のリゾートステイを優雅に快適におたのしみいただけます。
          </p>
          <CFocalCard
            data={[
              {
                link: {
                  href: '/stay/guestroom/#gardenside',
                },
                img: {
                  src: '/assets/images/stay/img_gardenside.png',
                  alt: 'ガーデンサイド',
                },
                title: {
                  en: <>garden side</>,
                  ja: <>ガーデンサイド</>,
                },
              },
              {
                link: {
                  href: '/stay/guestroom/#forestside',
                },
                img: {
                  src: '/assets/images/stay/img_forestside.png',
                  alt: 'フォレストサイド',
                },
                title: {
                  en: <>Forest side</>,
                  ja: <>フォレストサイド</>,
                },
              },
            ]}
          />
          <CLinkList
            data={[
              {
                label: '宿泊プラン',
                link: {
                  href: 'https://rsv.ihonex.com/cgi-bin/ihonex3/search_plan.cgi?hid=rphs_sendai',
                  blank: true,
                },
              },
              {
                label: '館内施設',
                link: {
                  href: '/stay/facilities/',
                },
              },
            ]}
          />
        </LWrap>

        <Search formId="toppage" />

        <div className="u_mbExLarge">
          <LPlan />
        </div>

        <CSectTitle title={{ ja: <>ゲストルーム</>, en: <>GUEST ROOM</> }} />
        <LBgSect
          size="small"
          img={{
            src: '/assets/images/stay/img_guestroom.png',
          }}
          imgSp={{
            src: '/assets/images/stay/img_guestroom__sp.png',
          }}
        >
          <h3 className="c_headingLv3 u_tac u_colorWhite">
            <span>garden side</span>
            <br />
            <br />
            美しい庭園がたのしめる
            <br />
            ガーデンサイドルーム
          </h3>
          <p className="c_sectLead u_colorWhite u_mbMedium">
            緑が広がる美しい庭園がたのしめる。
            <br className="u_pc" />
            庭園では結婚式や季節のイベントなどが開催されており、色々なシーンとの出会いもたのしみのひとつです。
          </p>
          <CBtnList
            data={[
              {
                label: 'ガーデンサイドルーム',
                link: {
                  href: '/stay/guestroom/#gardenside',
                },
                color: 'borderWhite',
              },
            ]}
          />
        </LBgSect>
        <LBgSect
          size="small"
          img={{
            src: '/assets/images/stay/img_guestroom02.png',
          }}
          imgSp={{
            src: '/assets/images/stay/img_guestroom02__sp.png',
          }}
        >
          <h3 className="c_headingLv3 u_tac u_colorWhite">
            <span>forest side</span>
            <br />
            樹々の色彩がたのしめる
            <br />
            フォレストサイドルーム
          </h3>
          <p className="c_sectLead u_colorWhite u_mbMedium">
            四季折々の樹々の色彩がたのしめる。
            <br className="u_pc" />
            東北の美しい季節の景色と気持ち良い爽やかな風が心と身体に癒やしを与えてくれます。
          </p>
          <CBtnList
            data={[
              {
                label: 'フォレストサイドルーム',
                link: {
                  href: '/stay/guestroom/#forestside',
                },
                color: 'borderWhite',
              },
            ]}
          />
        </LBgSect>
      </section>
      <div className="l_sect04">
      <CSectTitle title={{ ja: <>朝食</>, en: <>BREAKFAST</> }} />
        <CSpSliderMedia
          data={[
            {
              img: {
                src: '/assets/images/stay/img_breakfast.png',
                alt: '',
              },
              imgSub: {
                src: '/assets/images/stay/img_breakfast02.png',
                alt: '',
              },
              imgSp: [
                {
                  imgSp: {
                    src: '/assets/images/stay/img_breakfast__sp.png',
                    alt: '',
                  },
                },
                {
                  imgSp: {
                    src: '/assets/images/stay/img_breakfast02__sp.png',
                    alt: '',
                  },
                },
              ],
              title: (
                <>
                  気持ちいい環境と美味しい料理で
                  <br className="u_sp" />
                  ”とっておきの朝食体験”
                  <br className="u_sp" />
                  リゾートステイならではの贅沢
                </>
              ),
              text: (
                <>
                  美しい景色と新鮮な空気の中で、東北各地のその時季の食材を使った料理を出来たてでたのしむ。
                  <br />
                  ご宿泊の際は、ここでしか味わえない仙台ロイヤルパークホテルの自慢の美味しい朝食をぜひおたのしみください。
                </>
              ),
              btn: {
                label: '朝食について',
                link: {
                  href: '/restaurants/breakfast/',
                },
                color: 'borderBlack',
              },
              reverse: true,
            },
          ]}
        />

        <LWrap exClass="u_mbExLarge">
          <CSectTitle title={{ ja: <>アクティビティ</>, en: <>ACTIVITY</> }} />
          <CHeroCard
            data={[
              {
                title: {
                  ja: (
                    <>
                      <span className="c_headingLv4 font-fCG">
                        モーニングヨガ
                      </span>
                    </>
                  ),
                  en: '',
                },
                img: {
                  src: '/assets/images/stay/img_activity.png',
                  alt: '',
                },
                imgSp: {
                  src: '/assets/images/stay/img_activity__sp.png',
                  alt: '',
                },
                text: (
                  <>
                    <p className="u_tac_pc u_mt20">
                    滞在中の心身のリフレッシュに、モーニングヨガでウェルネスな
                    <br className="u_pc" />
                    一日をお過ごしください。
                    </p>
                  </>
                ),
                btn: {
                  label: 'モーニングヨガ',
                  link: {
                    href: 'https://www.srph.co.jp/experience/activity/dqags4obj/',
                  },
                },
              },
              {
                title: {
                  ja: (
                    <>
                      <span className="c_headingLv4 font-fCG">
                        宙ネタリウム
                      </span>
                    </>
                  ),
                  en: '',
                },
                img: {
                  src: '/assets/images/stay/img_activity02.png',
                  alt: '',
                },
                imgSp: {
                  src: '/assets/images/stay/img_activity02__sp.png',
                  alt: '',
                },
                text: (
                  <>
                    <p className="u_tac_pc u_mt20">
                      星のソムリエ®の資格を持つスタッフが、夜空を眺めながら四季折々の星や星座をご紹介いたします。
                      <br className="u_pc" />
                      ホテルのガーデンで味わえるアクティビティをおたのしみください。
                    </p>
                  </>
                ),
                btn: {
                  label: '宙ネタリウム',
                  link: {
                    href: 'https://www.srph.co.jp/experience/activity/kami79c6m/',
                  },
                },
              },
            ]}
          />
        </LWrap>

        <CSectTitle
          title={{ ja: <>フォンテーヌ</>, en: <>LOUNGE</> }}
        />
        <CSpSliderMedia
          data={[
            {
              img: {
                src: '/assets/images/stay/img_fontaine.png',
                alt: '',
              },
              imgSub: {
                src: '/assets/images/stay/img_fontaine02.png',
                alt: '',
              },
              imgSp: [
                {
                  imgSp: {
                    src: '/assets/images/stay/img_fontaine__sp.png',
                    alt: '',
                  },
                },
                {
                  imgSp: {
                    src: '/assets/images/stay/img_fontaine02__sp.png',
                    alt: '',
                  },
                },
              ],
              title: (
                <>
                  2024年10月1日（火）よりBar営業再開
                  <br />
                  ご宿泊の方はバータイムでの飲食を30%割引でご利用いただけます
                </>
              ),
              text: (
                <>
                </>
              ),
              info: (
                <>
                  ティータイム　10:30～17:30 <br />
                  バータイム　　17:30～22:00
                  <ul className="c_noteList u_mt15">
                    <li>2024年9月30日（月）をもって、モーニングタイム、ハッピーアワーは終了いたしました。</li>
                  </ul>
                </>
              ),
            },
          ]}
        />

        <LWrap exClass="u_mbExLarge">
          <CSectTitle title={{ ja: <>宿泊者特典</>, en: <>BENEFITS</> }} />
          <CHeroCard
            data={[
              {
                title: {
                  ja: (
                    <>
                      <span className="c_headingLv4 font-fCG">
                        ホテル内エステサロン <br className="u_sp" />
                        全グランドメニュー20％OFF
                      </span>
                    </>
                  ),
                  en: '',
                },
                img: {
                  src: '/assets/images/stay/img_benefits.png',
                  alt: '',
                },
                imgSp: {
                  src: '/assets/images/stay/img_benefits__sp.png',
                  alt: '',
                },
                text: (
                  <>
                    <p className="u_tac_pc u_mt20">
                      ご宿泊のお客様（女性限定）は、アーユソーマの全グランドメニューを
                      <br className="u_pc" />
                      「20％OFF」でご利用いただけます。
                    </p>
                  </>
                ),
                btn: {
                  label: 'エステ',
                  link: {
                    href: '/aesthetic/',
                  },
                },
              },
              {
                title: {
                  ja: (
                    <>
                      <span className="c_headingLv4 font-fCG">
                        仙台泉プレミアム・アウトレット <br className="u_sp" />
                        クーポンシート引換券付
                      </span>
                    </>
                  ),
                  en: '',
                },
                img: {
                  src: '/assets/images/stay/img_benefits02.png',
                  alt: '',
                },
                imgSp: {
                  src: '/assets/images/stay/img_benefits02__sp.png',
                  alt: '',
                },
                text: (
                  <>
                    <p className="u_tac_pc u_mt20">
                      ホテル隣「仙台泉プレミアム・アウトレット」各店舗でご利用
                      <br className="u_pc" />
                      いただけるクーポンシート付！ショッピングをおたのしみください。
                    </p>
                  </>
                ),
                btn: {
                  label: '仙台泉プレミアム・アウトレットのご案内',
                  link: {
                    href: 'https://www.premiumoutlets.co.jp/sendaiizumi/',
                    blank: true,
                  },
                },
              },
              {
                title: {
                  ja: (
                    <>
                      <span className="c_headingLv4 font-fCG">
                        スパメッツァ仙台　竜泉寺の湯ご優待
                      </span>
                    </>
                  ),
                  en: '',
                },
                img: {
                  src: '/assets/images/stay/img_benefits03.png',
                  alt: '',
                },
                imgSp: {
                  src: '/assets/images/stay/img_benefits03__sp.png',
                  alt: '',
                },
                text: (
                  <>
                    <p className="u_tac_pc u_mt20">
                    ホテルから車で約12分のスパメッツァ仙台は、
                    <br className="u_pc" />
                    日本最多の6つの炭酸泉をはじめ、魅力的な16種のお風呂をラインナップ。
                    <br className="u_pc" />
                    伊達なサウナやハーブ香る杜の潤いソルトサウナで「極上のととのい体験」を。
                    </p>
                  </>
                ),
                btn: {
                  label: 'スパメッツァ仙台のご案内',
                  link: {
                    href: 'https://ryusenjinoyu.com/spametsasendai/metsa_nelio',
                    blank: true,
                  },
                },
              },
            ]}
          />
        </LWrap>

        <LWrap>
          <CSectTitle title={{ ja: <>館内施設</>, en: <>FACILITIES</> }} />
          <p className="c_sectLead u_mbMedium">
            日常の喧騒から離れ、ゆったりとリゾートステイ
            <br />
            のびのびと過ごせる客室や多彩な味覚を揃えたレストラン、
            <br />
            エステ、アクティビティなど
            色々なシーンでリゾートステイをご堪能ください。
          </p>
          <CBtnList
            data={[
              {
                label: '施設・サービスについて',
                link: {
                  href: '/stay/facilities/',
                },
                color: 'borderBlack',
              },
            ]}
          />
        </LWrap>
      </div>
      <div className="u_bgGrad l_sect u_pb0">
        <LWrap>
          <CHeroMedia02
            data={[
              {
                title: (
                  <>
                    すぐそこにあるリゾートで
                    <br />
                    非日常のOMOTENASHIを
                    <span className="">「心ゆくまで、たのしむ」</span>
                  </>
                ),
                img: {
                  src: '/assets/images/stay/img_hero.png',
                  alt: '',
                },
                text: (
                  <>
                    仙台駅から車で約30分とは思えない心が落ち着く自然溢れる美しい景色とヨーロッパの趣を感じる体がほぐれる優雅で快適な空間。
                    <br />
                    杜の都を訪れる要人を迎えてきたOMOTENASHIを思う存分おたのしみください。
                  </>
                ),
                btn: {
                  label: 'ホテルの魅力',
                  link: {
                    href: '/about/',
                  },
                },
              },
            ]}
          />
        </LWrap>
      </div>
      <LPickup />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
